/* eslint-disable indent */
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../common/state/store";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { IMedia } from "../../../model/unityObject";
import { useLocation, useNavigate } from "react-router-dom";

import { mediaSelector, setMediaFilter } from "../../../features/gallery/state/gallerySlice";

import { IsLoading } from "../../../common/components/AppState/IsLoading";
import {
  closeStandardModal,
  openStandardModal,
} from "../../../common/state/slice/modal/modalSlice";

import { DynamicField, DynamicMedia } from "./DynamicField";
import ModalGallery from "./ModalGallery";
import { menuItems } from "../../../common/components/Icon/ThreeDotsDropdownMenu";
import { useTranslation } from "react-i18next";
import { createNewScene, getCurrentProject } from "../../projects/state/projectsSlice";
import { Field } from "../util/graphFieldsExtractor";
import InfoButton from "../../../common/components/InfoButton/InfoButton";
import { PlusIcon } from "@heroicons/react/outline";
import { FooterButtons } from "../../../common/components/FooterButtons";
import canSaveProject from "../util/canSaveProject";

const CREATE_NEW_SCENE_COMPONENT_ID = "createNewSceneComponentId";

export const CreateNewScene = () => {
  const mediasList = useSelector(mediaSelector);
  const currentProject = useSelector(getCurrentProject);
  const currentFilter = useSelector((state: RootState) => state.media.currentFilter);
  const [currentMediaId, setCurrentMediaId] = useState<number>(-1);
  const [currentModel3dId, setCurrentModel3dId] = useState<number>(-1);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch: AppDispatch = useDispatch();

  const { register, control, getValues, watch, handleSubmit } = useForm<any>();

  const nameField: Field = { type: "text", fieldName: "name", currentValue: "" };

  const watchFields = watch();
  useEffect(() => {
    setIsSubmitButtonDisabled(
      (currentMediaId ?? 0) < 1 || getValues("name") === "" || !getValues("name"),
    );
  }, [watchFields]);

  const onSubmit = () => {
    // ?
  };

  useEffect(() => {
    dispatch(closeStandardModal("modal-gallery"));
  }, []);

  const createScene = () => {
    const sceneName = getValues("name");

    dispatch(
      createNewScene({
        componentId: CREATE_NEW_SCENE_COMPONENT_ID,
        projectId: currentProject.id,
        mediaId: currentMediaId,
        model3d: currentModel3dId,
        sceneName,
      }),
    )
      .then(() => {
        if (location.pathname.includes("templates")) {
          navigate(`/templates/${currentProject.id}`);
        } else {
          navigate("../graph");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getEnhancedField = () => {
    return {
      type: "media",
      mediaType: "image",
      fieldName: "source_media",
      currentValue: currentMediaId,
      relevantSourceMedia: mediasList.find((m: IMedia) => Number(m.id) === Number(currentMediaId)),
    };
  };

  const enhancedField = getEnhancedField();

  const getEnhancedFieldModel3d = () => {
    return {
      type: "media",
      mediaType: "model3d",
      fieldName: "source_model3d",
      currentValue: currentMediaId,
      relevantSourceMedia: mediasList.find(
        (m: IMedia) => Number(m.id) === Number(currentModel3dId),
      ),
    };
  };

  const enhancedFieldModel3d = getEnhancedFieldModel3d();

  const handleOpenGallery = (mediaType: string) => {
    dispatch(setMediaFilter({ ...currentFilter, mediaType }));
    dispatch(openStandardModal("modal-gallery"));
  };

  const onMediaChange = (selectedMedia: IMedia) => {
    dispatch(closeStandardModal("modal-gallery"));

    if (currentFilter.mediaType === "model3d") {
      setCurrentModel3dId(selectedMedia.id);
    } else {
      setCurrentMediaId(selectedMedia.id);
    }
  };

  const removeEnv3d = () => {
    setCurrentModel3dId(-1);
  };

  const model3dMenuItems: menuItems = [
    { menuItemText: t("general.delete"), icon: "DocumentRemoveIcon", itemId: "deleteEnv3d" },
  ];

  const handleButtonClickModel3d = (mediaId: number, item: any) => {
    if (item.itemId === "deleteEnv3d" && mediaId > 0) {
      removeEnv3d();
    } else {
      handleOpenGallery("model3d");
    }
  };

  const canSave = canSaveProject(currentProject);
  return (
    <>
      <ModalGallery nodeId={`newscene-${currentProject.id}`} onMediaChange={onMediaChange} />
      <div className="flex flex-col w-full h-full mt-4 mx-auto flex flex-col justify-between">
        <InfoButton relevantArticle="howToLinkScene" />
        <IsLoading showSuccess={true} componentId={CREATE_NEW_SCENE_COMPONENT_ID} />
        <form className="relative w-full max-h-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="max-w-3xl mx-auto ">
            <div className="w-full flex flex-col">
              <div>
                <h2 className="flex mb-6 items-center justify-center font-bold h-[15%]">
                  {t("general.create")}
                </h2>
              </div>
              <div className=" max-w-md h-[75%]">
                <DynamicField field={nameField} register={register} />
              </div>
            </div>

            <div className="flex pt-2 w-full">
              <div className="mt-2 w-80  mr-9">
                <h2 className=" text-xl w-full max-w-7xl mx-auto mb-4 mt-4">
                  {t("general.backgroundMedia")}
                </h2>
                <div>
                  <Controller
                    control={control}
                    name={enhancedField.fieldName}
                    render={({ field }) => (
                      <DynamicMedia
                        field={field}
                        fieldType={enhancedField.type}
                        register={register}
                        handleButtonClick={() => {
                          handleOpenGallery("image");
                        }}
                        currentMedia={enhancedField.currentValue as number}
                        media={enhancedField.relevantSourceMedia as IMedia}
                      />
                    )}
                  />
                </div>
              </div>

              {currentModel3dId && currentModel3dId > 0 && (
                <div className="w-80 h-72  mr-9 mt-2">
                  <h2 className="text-xl w-full max-w-7xl mx-auto mb-4 mt-4">
                    {t("general.3dEnv")}
                  </h2>
                  <div>
                    <Controller
                      control={control}
                      name={enhancedFieldModel3d.fieldName}
                      render={({ field }) => (
                        <DynamicMedia
                          field={field}
                          fieldType={enhancedFieldModel3d.type}
                          register={register}
                          handleButtonClick={(mediaId, item) => {
                            handleButtonClickModel3d(mediaId, item);
                          }}
                          currentMedia={enhancedFieldModel3d.currentValue as number}
                          media={enhancedFieldModel3d.relevantSourceMedia as IMedia}
                          customMenuItems={model3dMenuItems}
                        />
                      )}
                    />
                  </div>
                </div>
              )}

              {(!currentModel3dId || currentModel3dId < 1) && (
                <div>
                  <h2 className="text-xl w-full max-w-7xl mx-auto  mt-6">{t("general.3dEnv")}</h2>
                  <div>
                    <button
                      className="btn-alternative-outline mt-2.5 button-enviroment3d text-green-500"
                      onClick={() => handleOpenGallery("model3d")}
                    >
                      {<PlusIcon className="h-6 w-6 ml-36 mb-5" />}
                      {t("general.add3dEnv")}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
        <FooterButtons
          cancelText={t("general.cancel")}
          cancelCb={() => {
            if (location.pathname.includes("templates")) {
              navigate(`/templates/${currentProject.id}`);
            } else {
              navigate("../graph");
            }
          }}
          nextDisabled={isSubmitButtonDisabled}
          nextText={t("general.save")}
          nextCb={createScene}
          nextDisabledTooltipText={
            canSave.reason ? t(canSave.reason) : t("tooltip.sceneMustHaveNameAndMedia")
          }
        />
      </div>
    </>
  );
};
