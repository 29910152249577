interface PlatformInfos {
  buildDurationInMin: number;
  isManualBuild: boolean;
}

export const getPlatformBuildInfo = (platform: string): PlatformInfos | null => {
  switch (platform) {
    case "MetaQuest":
      return {
        buildDurationInMin: 1,
        isManualBuild: false,
      };
    case "PicoNeo":
      return {
        buildDurationInMin: 1,
        isManualBuild: false,
      };
    case "Windows10":
      return {
        buildDurationInMin: 1,
        isManualBuild: false,
      };
    case "WebGLApp":
      return {
        buildDurationInMin: 0,
        isManualBuild: true,
      };
    case "WebGLiframe":
      return {
        buildDurationInMin: 0,
        isManualBuild: true,
      };
    case "Android":
      return {
        buildDurationInMin: 1,
        isManualBuild: false,
      };
    case "MacOS":
      return {
        buildDurationInMin: 90,
        isManualBuild: true,
      };
    case "iPhone":
      return {
        buildDurationInMin: 0,
        isManualBuild: true,
      };
    case "iPad":
      return {
        buildDurationInMin: 0,
        isManualBuild: true,
      };
    default:
      return null;
  }
};
