import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, Input } from "../../common/components/Input";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import ProfileInputStyle from "../../common/components/Input/ProfileInputStyle";
import { AppDispatch, RootState } from "../../common/state/store";
import { ILanguage, IOrganization, IUser } from "../../model/model";
import {
  getCurrentOrganization,
  updateUser,
  getCurrentUser,
  setCurrentUserAndOrg,
  checkEmail,
  userOpenedTutorialReset,
  getUserOpenedTutorial,
} from "../../features/profile/state/profileSlice";
import { IsLoading } from "../../common/components/AppState/IsLoading";
import { PayloadAction } from "@reduxjs/toolkit";
import ModalGallery from "../../features/graph/components/ModalGallery";
import ChangePasswordPopup from "../../common/components/Profile/ChangePasswordPopup";
import ChangeEmailPoppup from "../../common/components/Profile/ChangeMailConfirmPassword";
import Avatar3dPopup from "../../common/components/Profile/Avatar3dPopup";
import Spinner from "../../common/components/Loader/Spinner";
import {
  closeStandardModal,
  getCurrentModalCurrentState,
} from "../../common/state/slice/modal/modalSlice";
import RoundImagePreview from "../../features/projects/components/RoundImagePreview";
import { setAlert } from "../../features/alert/state/alertsSlice";
import { useCallbackPrompt } from "../../common/util/useCallbackPrompt";
import ModalConfirmBeforeNavigatingOut from "../../common/components/Modal/ModalConfirmBeforeNavigatingOut";
export const PROFILE_COMPONENT_ID = "profileComponent";
export const AVATAR3D_COMPONENT_ID = "avatar3dComponent";
export const CHANGEPASSWORD_COMPONENT_ID = "changePasswordComponent";
export const CHECK_EMAIL_COMPONENT_ID = "CkeckEmailComponent";

type ProfileValues = {
  firstname: string;
  lastname: string;
  email: string;
  source_avatar: number;
  avatar: string;
  preferred_language: number;
};

const languageDropdownOptions = [
  {
    disabled: false,
    value: "1",
    optionText: "Français",
  },
  {
    disabled: false,
    value: "2",
    optionText: "English",
  },
];

const ProfileMain = () => {
  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();
  const [isConfirmPasswordOpen, setIsConfirmPasswordOpen] = useState(true);
  const [isAvatar3dLoading, setIsAvatar3dLoading] = useState(true);

  const { currentUser, organization, userOpenedTutorial } = useSelector((state: RootState) => {
    return {
      userOpenedTutorial: getUserOpenedTutorial(state),
      currentUser: getCurrentUser(state),
      organization: getCurrentOrganization(state),
    };
  });
  const buttonResetDisabled = !Object.values(userOpenedTutorial as any).some(
    (value) => value === true,
  );

  const setFocusOnInput = (inputId: "firstname" | "lastname") => {
    // Wait for the next render cycle to set the focus
    setTimeout(() => {
      setFocus(inputId);
    }, 0);
  };

  useEffect(() => {
    if (!currentUser || !organization) {
      dispatch(setCurrentUserAndOrg({ componentId: PROFILE_COMPONENT_ID })).then((action) => {
        const temp = action as PayloadAction<{ currentUser: IUser; organization: IOrganization }>;

        reset({
          firstname: temp.payload.currentUser?.firstname,
          lastname: temp.payload.currentUser?.lastname,
          email: temp.payload.currentUser?.email,
          source_avatar: Number(temp.payload.currentUser?.source_avatar?.id),
        });
      });
    }

    dispatch(closeStandardModal("modal-gallery"));
  }, []);

  const [disabledFirstName, setDisabledFirstName] = useState(true);
  const [disabledLastName, setDisabledLastName] = useState(true);
  const [disabledEmail, setDisabledEmail] = useState(true);
  const [disabledPassword, setDisabledPassword] = useState(true);
  const [disabledRole, setDisabledRole] = useState(true);
  const modalState = useSelector(getCurrentModalCurrentState);
  // const userPrefLanguage = currentUser?.preferred_language as ILanguage;

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    getValues,
    setFocus,
    formState: { isDirty, errors: errorsProfile },
  } = useForm<ProfileValues>({
    defaultValues: {
      firstname: currentUser?.firstname,
      lastname: currentUser?.lastname,
      email: currentUser?.email,
      source_avatar: Number(currentUser?.source_avatar?.id),
      avatar: currentUser?.avatar3d_url,
      preferred_language: Number((currentUser?.preferred_language as ILanguage)?.id),
    },
  });
  useEffect(() => {
    if (modalState.showConfirmationModal.show === false) {
      setValue("email", currentUser?.email ? currentUser?.email : "");
    }
  }, [modalState]);
  const watchEmail = watch("email");
  const onSubmitProfile = (data: ProfileValues) => {
    dispatch(
      checkEmail({
        componentId: CHECK_EMAIL_COMPONENT_ID,
        email: data.email,
        id: currentUser?.id,
      }),
    ).then((response) => {
      if (response.payload) {
        const updatedUser = {
          ...(currentUser as any),
          firstname: data.firstname,
          lastname: data.lastname,
          email: data.email?.toLocaleLowerCase(),
          preferred_language: data.preferred_language,
          source_avatar: data.source_avatar,
        };
        //modalConfirmPasswordForEmail close

        setIsConfirmPasswordOpen(true);
        dispatch(closeStandardModal("modalConfirmPasswordForEmail"));

        dispatch(updateUser({ componentId: PROFILE_COMPONENT_ID, ...updatedUser }))
          .then(() => {
            reset({
              firstname: data.firstname,
              lastname: data.lastname,
              email: data.email,
              source_avatar: data.source_avatar,
              preferred_language: data.preferred_language,
            });

            setDisabledFirstName(true);
            setDisabledLastName(true);
            setDisabledEmail(true);
            setDisabledPassword(true);
            // time out to wait for the reset to finish user data update
            setTimeout(() => {
              dispatch(
                setAlert({
                  type: "success",
                  msg: t("alerts.profileEditSuccess"),
                }),
              );
            }, 1);
          })
          .catch((e: any) => {
            console.error("catch: ", e);
          });
      } else {
        reset({
          firstname: currentUser?.firstname,
          lastname: currentUser?.lastname,
          email: currentUser?.email,
          source_avatar: Number(currentUser?.source_avatar?.id),
          preferred_language: Number((currentUser?.preferred_language as ILanguage)?.id),
        });
        setDisabledFirstName(true);
        setDisabledLastName(true);
        setDisabledEmail(true);
        setDisabledPassword(true);
        dispatch(
          setAlert({
            type: "danger",
            msg: t("alerts.emailCheck"),
          }),
        );
      }
    });
  };

  const cancel = () => {
    reset({
      firstname: currentUser?.firstname,
      lastname: currentUser?.lastname,
      email: currentUser?.email,
      source_avatar: Number(currentUser?.source_avatar?.id),
      preferred_language: Number((currentUser?.preferred_language as ILanguage)?.id),
    });
    setDisabledFirstName(true);
    setDisabledLastName(true);
    setDisabledEmail(true);
    setDisabledPassword(true);
  };

  const handleAvatar3dChange = (url: string) => {
    if (currentUser?.avatar3d_url !== url) {
      dispatch(
        updateUser({
          componentId: PROFILE_COMPONENT_ID,
          ...(currentUser as IUser),
          avatar3d_url: url,
        }),
      );
    }
  };

  const userIsAdminOfOrg = Number(currentUser?.id) === Number(organization?.admin.id);
  const hasAnythingChanged =
    Number(currentUser?.source_avatar?.id) !== watch("source_avatar") || isDirty;

  const getRoleName = (currentUser?: IUser) => {
    if (userIsAdminOfOrg) {
      return t("general.roles.admin");
    } else if (Number(currentUser?.role.id) === 5) {
      return t("general.roles.creator");
    } else {
      return t("general.roles.enduser");
    }
  };

  const hasSomethingChanged = () => {
    if (currentUser?.firstname !== getValues("firstname")) {
      return true;
    }

    if (currentUser?.lastname !== getValues("lastname")) {
      return true;
    }

    if (currentUser?.email !== getValues("email")) {
      return true;
    }

    if (Number(currentUser?.source_avatar.id) !== getValues("source_avatar")) {
      return true;
    }

    if (
      Number((currentUser?.preferred_language as ILanguage).id) !==
      Number(getValues("preferred_language"))
    ) {
      return true;
    }

    return false;
  };

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    hasSomethingChanged(),
  );
  const resetTutorial = () => {
    dispatch(userOpenedTutorialReset({ componentId: PROFILE_COMPONENT_ID })).then((res: any) => {
      if (res.payload) {
        dispatch(
          setAlert({
            type: "success",
            msg: t("alerts.tutorialResetSuccess"),
          }),
        );
      } else {
        dispatch(
          setAlert({
            type: "danger",
            msg: t("alerts.tutorialResetFail"),
          }),
        );
      }
    });
  };

  return (
    <>
      <ChangePasswordPopup email={currentUser?.email} />
      <Avatar3dPopup
        currentUserAvatar3dUrl={currentUser.avatar3d_url}
        handleAvatar3dChange={handleAvatar3dChange}
      />
      <ModalGallery
        nodeId="profile"
        onMediaChange={(e: any) => {
          const selectedMediaId = Number(e.id);
          setValue("source_avatar", selectedMediaId);
          dispatch(closeStandardModal("modal-gallery"));
        }}
      />
      <div className="relative grow w-full pl-4 pr-4 px-4 mx-auto personalize-scroll-visible overflow-y-auto">
        <h2 className="text-2xl font-medium mb-2 mt-2 xl:mt-4">
          {t("pages.profile.tabs.myDetails")}
        </h2>
        <h3 className="py-3">{t("pages.profile.main.subheading")}</h3>
        <div className="completely-centeredProfile">
          <IsLoading componentId={PROFILE_COMPONENT_ID} showSuccess={false} />
        </div>
        <div className="mt-2 border-gray-300">
          <form className="h-full" onSubmit={handleSubmit(onSubmitProfile)}>
            <ProfileInputStyle
              title={t("pages.profile.main.firstName")}
              disabled={disabledFirstName}
              setDisabled={setDisabledFirstName}
              setFocus={() => setFocusOnInput("firstname")}
            >
              <Input
                type="text"
                highlight={disabledFirstName}
                readOnly={disabledFirstName}
                registerFct={() => register("firstname")}
                className="font-medium bg-white read-only:bg-slate-100 read-only:border-transparent read-only:shadow-none read-only:focus:ring-0 text-gray-600 max-w-max text-center sm:text-left border-0 ${ ? }"
                placeholder={t("pages.profile.main.firstNamePlaceholder")}
              />
            </ProfileInputStyle>
            <ProfileInputStyle
              title={t("pages.profile.main.lastName")}
              disabled={disabledLastName}
              setDisabled={setDisabledLastName}
              setFocus={() => setFocusOnInput("lastname")}
            >
              <Input
                type="text"
                highlight={disabledLastName}
                readOnly={disabledLastName}
                registerFct={() => register("lastname")}
                className="font-medium bg-white read-only:bg-slate-100 read-only:border-transparent read-only:shadow-none read-only:focus:ring-0 text-gray-600  max-w-max text-center sm:text-left border-0 ${ ? }"
                placeholder={t("pages.profile.main.lastNamePlaceholder")}
              />
            </ProfileInputStyle>
            <ProfileInputStyle
              title={t("pages.profile.main.email")}
              disabled={disabledEmail}
              setDisabled={setDisabledEmail}
            >
              <Input
                type="email"
                readOnly={disabledEmail}
                registerFct={() => register("email", { required: true, pattern: /^\S+@\S+$/i })}
                className="mr-2 font-medium bg-white read-only:bg-slate-100 read-only:border-transparent read-only:shadow-none read-only:focus:ring-0 text-gray-600 text-center max-w-max sm:text-left border-0 ${ ? }"
                placeholder={t("pages.profile.main.emailPlaceholder")}
              />
              <ChangeEmailPoppup
                setIsConfirmPasswordOpen={setIsConfirmPasswordOpen}
                register={register}
                onsubmit={handleSubmit(onSubmitProfile)}
                isConfirmPasswordOpen={isConfirmPasswordOpen}
                email={watchEmail}
              />
              <ErrorMessage
                errors={errorsProfile}
                name="email"
                render={() => (
                  <div className="font-small text-red-700">{"Ne peux pas être vide!"}</div>
                )}
              />
            </ProfileInputStyle>
            <ProfileInputStyle title={t("pages.profile.main.photo")} setValueFct={setValue}>
              <RoundImagePreview
                componentId={PROFILE_COMPONENT_ID}
                media={watch("source_avatar")}
                alt="Logo"
                bigger
              />
            </ProfileInputStyle>
            <ProfileInputStyle
              title={t("pages.profile.main.avatar")}
              avatarStatus={
                isAvatar3dLoading
                  ? "loading"
                  : `<root>
              <status>
                  {
                      "state": "ready",
                      "message": "ERROR_SUCCESS",
                      "details": {
                          "type": "3D avatar",
                          "loading": false
                      },
                      "htmlResponse": "<!DOCTYPE html><html><head><title>Status</title><meta name='author' content='David Mayer'></head><body><h1>Status: OK</h1></body></html>"
                    }
              </status>
          </root>`
              }
            >
              <>
                <div
                  style={{ display: isAvatar3dLoading ? "block" : "none" }}
                  className="relative mb-10 mr-10 sm:ml-4"
                >
                  <Spinner />
                </div>

                <img
                  style={{ display: isAvatar3dLoading ? "none" : "block" }}
                  className="inline object-cover w-16 h-16 ml-4 rounded-full"
                  src={currentUser.avatar3d_url.replace(".glb", ".png")}
                  onLoad={() => {
                    setIsAvatar3dLoading(false);
                  }}
                  onError={(e) => console.error("Oopsie poopsie! 💩", e)}
                  alt="Profile image"
                />
              </>
            </ProfileInputStyle>

            <div className="w-full flex flex-col sm:flex-row items-center py-2 px-2 xl:py-3 xl:px-4 border-t border-gray-300">
              <p className="w-full sm:w-1/3 text-sm text-center sm:text-left">
                {t("pages.profile.main.language")}
              </p>

              <Dropdown
                options={languageDropdownOptions}
                asDefaultValue
                languague
                defaultValueIndex={languageDropdownOptions.findIndex((v) => {
                  const userPrefLanguage = currentUser?.preferred_language as ILanguage;
                  return Number(userPrefLanguage?.id) === Number(v.value);
                })}
                registerFct={() => register("preferred_language")}
              />
            </div>

            <div className="flex justify-end">
              <button
                type="button"
                className="btn-alternative-outline mr-4"
                onClick={() => {
                  cancel();
                }}
              >
                {t("general.cancel")}
              </button>
              <button className="btn-primary-fill" type="submit" disabled={!hasAnythingChanged}>
                {t("general.save")}
              </button>
            </div>
          </form>

          <h2 className="text-2xl font-medium mb-3 mt-8">{t("pages.profile.tabs.myAccount")}</h2>
          <ProfileInputStyle
            title={t("general.password")}
            disabled={disabledPassword}
            setDisabled={setDisabledPassword}
          >
            <Input
              type="text"
              readOnly={disabledPassword}
              className=" mr-2 font-medium bg-white read-only:bg-slate-100 read-only:border-transparent read-only:shadow-none read-only:focus:ring-0 read-only:placeholder-gray-600  max-w-max text-center sm:text-left border-0 ${ ? }"
              placeholder={"*********"}
            />
          </ProfileInputStyle>

          <ProfileInputStyle
            title={t("general.roles.role")}
            disabled={disabledRole}
            setDisabled={setDisabledRole}
          >
            <Input
              type="text"
              readOnly={disabledRole}
              className="font-medium bg-white read-only:bg-slate-100 read-only:border-transparent read-only:shadow-none read-only:focus:ring-0 text-gray-600 max-w-max text-center sm:text-left border-0 ${ ? }"
              value={getRoleName(currentUser)}
            />
          </ProfileInputStyle>
          <div className="flex justify-end mb-4 mr-1">
            <button
              className="btn-alternative-outline hidden" // WIXAR-4051 🙈
              onClick={() => resetTutorial()}
              disabled={buttonResetDisabled}
            >
              {t("pages.profile.tabs.resetTutorial")}
            </button>
          </div>
        </div>
      </div>
      <ModalConfirmBeforeNavigatingOut
        showDialog={showPrompt}
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
      />
    </>
  );
};

export default ProfileMain;
