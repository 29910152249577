import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/common/state/hooks";
import { createAndAssignProjectFromAiGraph } from "src/features/projects/state/projectsSlice";
import {
  fetchAndUpdateSpecificConversation,
  generateProjectGroupFromAiGraph,
  getActiveGptConversation,
} from "../state/chatbotSlice";
import { CHATBOT_COMPONENT_ID, createProjectFromAiGraphModalId } from "./Chatbot";
import { closeStandardModal } from "src/common/state/slice/modal/modalSlice";
import { setAlert } from "src/features/alert/state/alertsSlice";
import {
  fetchSpecificProjectGroupWithProjects,
  getAllProjectGroupsWithNestedElements,
} from "src/features/projectGroups/state/projectGroupsSlice";
import SearchableDropdown from "src/common/components/SearchableDropdown/SearchableDropdown";
import { Tooltip } from "@mui/material";

const leTimestampDuBaguetteGmtPlusDeux = new Date(Date.now() + 2 * 60 * 60 * 1000);
const prettyGenerationDate = new Intl.DateTimeFormat("fr-FR").format(
  leTimestampDuBaguetteGmtPlusDeux,
);

const CreateProjectFromAiGraph = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const activeGptConversation = useSelector(getActiveGptConversation);
  const projectGroupsWithNestedElements = useSelector(getAllProjectGroupsWithNestedElements);
  const [selectedOption, setSelectedOption] = useState("");
  const [name, setName] = useState(`Généré le ${prettyGenerationDate}`);
  const [errorMessage, setErrorMessage] = useState("");
  const [generateBlockadeImages, setGenerateBlockadeImages] = useState(false);
  const [assignedToProjectGroup, setAssignedToProjectGroup] = useState("");

  const validateAndSubmit = async () => {
    // Validate the input
    if (!name) {
      setErrorMessage("Le nom ne peut pas être vide.");
      return;
    }
    if (name.length > 24) {
      setErrorMessage("Le nom ne peut pas dépasser 24 caractères.");
      return;
    }
    setErrorMessage("");
    dispatch(closeStandardModal(createProjectFromAiGraphModalId));

    try {
      if (selectedOption === "assignToProjectGroup") {
        // Dispatch action to create and assign project
        (await dispatch(
          createAndAssignProjectFromAiGraph({
            name,
            generateBlockadeImages,
            assignToProjectGroup: Number(assignedToProjectGroup),
            conversationId: activeGptConversation.id,
            componentId: CHATBOT_COMPONENT_ID,
            cb: () => {
              dispatch(
                fetchAndUpdateSpecificConversation({ conversationId: activeGptConversation.id }),
              );
              dispatch(
                setAlert({
                  msg: t("pages.chat.createProjectFromAiGraph.templateGeneratedSuccessfully"),
                  type: "success",
                  isOpen: true,
                  customTimeoutInMs: 15000,
                }),
              );
            },
          }),
        )) as any;

        await dispatch(
          fetchSpecificProjectGroupWithProjects({
            projectGroupId: Number(assignedToProjectGroup),
            componentId: CHATBOT_COMPONENT_ID,
          }),
        );
      }

      if (selectedOption === "generateProjectGroup") {
        // Dispatch action to generate a project group
        const response = (await dispatch(
          generateProjectGroupFromAiGraph({
            name,
            generateBlockadeImages,
            conversationId: activeGptConversation.id,
            componentId: CHATBOT_COMPONENT_ID,
          }),
        )) as any;

        const createdProjectGroupId = response.payload.createdProjectGroup?.id;

        await dispatch(
          fetchSpecificProjectGroupWithProjects({
            projectGroupId: createdProjectGroupId,
            componentId: CHATBOT_COMPONENT_ID,
          }),
        );

        dispatch(
          setAlert({
            msg: t("pages.chat.createProjectFromAiGraph.projectGroupGeneratedSuccessfully"),
            type: "success",
            isOpen: true,
            alertShowCloseButton: true,
            customTimeoutInMs: 60000,
          }),
        );
      }
    } catch (error) {
      console.error("Error in validateAndSubmit:", error);
      dispatch(
        setAlert({
          msg: t("pages.chat.createProjectFromAiGraph.errorWhileGenerating"),
          type: "error",
          isOpen: true,
          alertShowCloseButton: true,
        }),
      );
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Backspace") {
      event.stopPropagation();
    }
  };

  const InputWithCheckbox = ({ customElement }: { customElement: any }) => (
    <div className="flex flex-col items-start mb-4">
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder={t("pages.chat.createProjectFromAiGraph.enterName")}
        className="border rounded px-3 py-2 w-full mb-4"
        autoFocus
      />
      {customElement}
      <div className="flex items-center">
        <h2 className="text-[0.6rem] mr-2 text-center">
          {t("pages.chat.createProjectFromAiGraph.generateBlockadeImages")}
        </h2>
        <input
          type="checkbox"
          checked={generateBlockadeImages}
          onChange={() => setGenerateBlockadeImages(!generateBlockadeImages)}
          style={{
            height: "11px",
            width: "11px",
            border: "1px solid rgb(209 213 219)",
            cursor: "pointer",
          }}
        />
      </div>
    </div>
  );

  const AssignToProjectGroup = () => (
    <div className="w-full flex flex-col items-center justify-center">
      <h2 className="text-lg font-bold mb-4">
        {t("pages.chat.createProjectFromAiGraph.nameAndAssignNewModule")}
      </h2>
      <InputWithCheckbox
        customElement={
          <div className="mb-4 w-full">
            <SearchableDropdown
              options={projectGroupsWithNestedElements}
              selectedId={assignedToProjectGroup}
              onSelect={(selectedId) => {
                setAssignedToProjectGroup(selectedId.toString());
              }}
              placeholder={t("pages.chat.createProjectFromAiGraph.selectAProjectGroup")}
            />
          </div>
        }
      />
      {errorMessage && <p className="text-red-500 text-sm mb-2">{errorMessage}</p>}

      <Tooltip
        title={
          assignedToProjectGroup ? "" : t("pages.chat.createProjectFromAiGraph.selectAProjectGroup")
        }
      >
        <button
          className="btn-primary-fill"
          disabled={!assignedToProjectGroup}
          onClick={validateAndSubmit}
        >
          {t("general.generate")}
        </button>
      </Tooltip>
    </div>
  );

  const GenerateProjectGroup = () => (
    <div className="w-full flex flex-col items-center justify-center">
      <h2 className="text-lg font-bold mb-4">
        {t("pages.chat.createProjectFromAiGraph.nameNewProjectGroup")}
      </h2>
      <InputWithCheckbox customElement={null} />
      {errorMessage && <p className="text-red-500 text-sm mb-2">{errorMessage}</p>}
      <button className="btn-primary-fill" onClick={validateAndSubmit}>
        {t("general.generate")}
      </button>
    </div>
  );

  const renderAppropriateComponent = () => {
    switch (selectedOption) {
      case "assignToProjectGroup":
        return <AssignToProjectGroup />;
      case "generateProjectGroup":
        return <GenerateProjectGroup />;
      case "":
      default:
        return (
          <div className="w-full flex flex-col items-center justify-center">
            <h2 className="text-lg font-bold mb-4">
              {t("pages.chat.createProjectFromAiGraph.title")}
            </h2>
            <div className="flex w-3/5 items-center justify-between">
              <button
                className="btn-primary-fill"
                onClick={() => setSelectedOption("generateProjectGroup")}
              >
                {t("pages.chat.createProjectFromAiGraph.generateProjectGroup")}
              </button>
              <button
                className="btn-primary-fill"
                onClick={() => setSelectedOption("assignToProjectGroup")}
              >
                {t("pages.chat.createProjectFromAiGraph.assignToProjectGroup")}
              </button>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="w-full flex flex-col items-center justify-center">
      {renderAppropriateComponent()}
    </div>
  );
};

export default CreateProjectFromAiGraph;
