import { Menu, Transition } from "@headlessui/react";
import Icon, { IconName } from "./Icon";
import { useEffect, useState, useRef } from "react";

export interface SubMenuItem {
  id: string;
  text: string;
  icon?: IconName | (() => JSX.Element);
}

export interface DoubleDropdownProps {
  subMenuItems: SubMenuItem[];
  onSubMenuClick: (id: string) => void;
  subMenuPosition?: "left" | "right";
  type?: "project" | "projectGroup";
}

const DoubleDropdown = ({
  subMenuItems,
  onSubMenuClick,
  subMenuPosition,
  type = "projectGroup",
}: DoubleDropdownProps) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [adjustedPosition, setAdjustedPosition] = useState(subMenuPosition);

  useEffect(() => {
    if (dropdownRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const windowWidth = window.innerWidth;

      if (subMenuPosition === "right" && dropdownRect.right > windowWidth) {
        setAdjustedPosition("left");
      }

      if (subMenuPosition === "left" && dropdownRect.left < 0) {
        setAdjustedPosition("right");
      }
    }
  }, [subMenuPosition]);

  return (
    <div
      ref={dropdownRef}
      className={`absolute ${adjustedPosition === "right" ? "left-full" : "right-full"} top-0 ${
        type === "project" ? "w-48" : "w-56"
      } bg-white border border-gray-200 rounded-md shadow-lg focus:outline-none font-normal translate-y-[-5px]`}
    >
      <Transition
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items>
          {subMenuItems.map((subItem) => (
            <Menu.Item key={subItem.id}>
              <button
                className={`w-full ${
                  type === "project" ? "px-2 pt-1.5 pb-1" : "px-3 py-1.5"
                } text-sm hover:bg-gray-100 flex items-center`}
                onClick={() => onSubMenuClick(subItem.id)}
              >
                {typeof subItem.icon === "string" ? (
                  <Icon icon={subItem.icon} className="h-5 w-5 stroke-green-500 mr-2" />
                ) : (
                  <div className="w-5 h-5 mr-2">{subItem.icon()}</div>
                )}
                {subItem.text}
              </button>
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </div>
  );
};

export default DoubleDropdown;
