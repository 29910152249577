import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Tooltip } from "@mui/material";
import Badge from "../Badge/Badge";
import Icon from "../Icon/Icon";

export interface IAllowedItem {
  name: string;
  published?: boolean;
}

interface IDisplayAllowedItemsProps {
  style?: any;
  className?: string;
  itemsAreProjectGroups: boolean;
  hasAccessToEverything: boolean;
  allowedItems: IAllowedItem[];
}

export const DisplayAllowedItems: React.FC<IDisplayAllowedItemsProps> = ({
  style = { width: "30vw" }, // somehow using Tailwind didn't worked
  className,
  itemsAreProjectGroups,
  hasAccessToEverything,
  allowedItems,
}: IDisplayAllowedItemsProps) => {
  const { t } = useTranslation();
  const [isSeeAllOpen, setIsSeeAllOpen] = useState(false);

  const thereIsMoreThanThreeItems = allowedItems.length > 3;
  const items = allowedItems
    .filter((allowedItem, i) => {
      if (thereIsMoreThanThreeItems && !isSeeAllOpen) {
        return i <= 2;
      }
      return allowedItem.name;
    })
    .map((allowedItem, i) => {
      return (
        <Tooltip
          key={`hih${i}`}
          title={
            allowedItem.published
              ? t("alerts.publishedModule")
              : itemsAreProjectGroups // if items are project group, do not display any tooltip
              ? ""
              : t("pages.participants.thisModuleIsYetToBePublished")
          }
        >
          <div>
            {/* Don't remove this div: MUI Tooltip children needs to be able to hold a ref */}
            <Badge
              text={allowedItem.name}
              className={"p-2 mr-1 mb-1 select-none whitespace-nowrap"}
              color={allowedItem.published ? "bg-green-500" : "bg-gray-500"}
              textColor="text-gray-100"
            />
          </div>
        </Tooltip>
      );
    });

  return (
    <div className={className} style={style}>
      {hasAccessToEverything ? (
        <Badge
          className={"p-2 select-none"}
          color="bg-yellow-400"
          textColor="text-gray-100"
          text={
            itemsAreProjectGroups
              ? t("pages.collaborators.hasAccessToAll")
              : t("pages.participants.hasAccessToAll")
          }
        />
      ) : (
        <div className="flex items-start w-full">
          <div className="flex flex-wrap">
            {items.length > 0 ? items : <div className="italic">{t("general.noPermission")}</div>}
            {thereIsMoreThanThreeItems && (
              <Icon
                icon={isSeeAllOpen ? "MinusSmIcon" : "PlusSmIcon"}
                className="bg-green-500 rounded-full text-white h-[1.1rem] w-[1.1rem] mt-1"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsSeeAllOpen(!isSeeAllOpen);
                }}
                aria-expanded={isSeeAllOpen}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
