export const isMarkdownShapedLikeAScenario = (markdownText: string): boolean => {
  const sceneRegex = /^### Scène n° \d+:/gm;
  const interactionRegex = /^-\s.*?:/gm;

  const scenes = markdownText.match(sceneRegex);
  if (!scenes || scenes.length === 0) return false;

  return scenes.some((scene, index) => {
    const currentSceneStart = markdownText.indexOf(scene);
    const nextSceneStart =
      index + 1 < scenes.length ? markdownText.indexOf(scenes[index + 1]) : markdownText.length;

    const sceneBlock = markdownText.slice(currentSceneStart, nextSceneStart);
    return interactionRegex.test(sceneBlock);
  });
};
