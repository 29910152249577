import { useTranslation } from "react-i18next";
import { CreateOrUpdateUserAsAdminForm } from "./CreateOrUpdateUserAsAdminForm";
import { CreateOrUpdateUserAsAdminPermissionsList } from "./CreateOrUpdateUserAsAdminPermissionsList";
import { useForm } from "react-hook-form";
import {
  ICreatorDetailsPermission,
  IEnduserDetailsPermission,
  InviteExternalFormData,
} from "src/features/team/state/teamInterfaces";
import { FooterButtons } from "../FooterButtons";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  editCreatorPerms,
  editEnduserPerms,
  getCreateOrEditUserDraft,
  getCreators,
  getEndusers,
  inviteCreatorWithPerms,
  inviteEnduserWithPerms,
  inviteExternalEnduserWithoutEmail,
} from "src/features/team/state/teamSlice";
import { Checkbox } from "../Input";
import { getAllProjectGroupsWithNestedElements } from "src/features/projectGroups/state/projectGroupsSlice";
import { useAppDispatch } from "src/common/state/hooks";
import { COLLABORATORS_TABLE_COMPONENT } from "src/pages/Collaborators/CollaboratorsList";
import { PARTICIPANTS_TABLE_COMPONENT } from "src/pages/Participants/ParticipantsList";
import { getCurrentUser } from "src/features/profile/state/profileSlice";
import { displayProperAlert } from "src/common/util/displayProperAlert";
import { RootState } from "src/common/state/store";
import {
  fetchSubscription,
  getSubscriptionFeature,
} from "src/features/subscription/state/subscriptionSlice";
import { AvailableFeatureCounter } from "../AvailableCounter/AvailableFeatureCounter";
import { IsLoading } from "../AppState/IsLoading";

const NEW_SOMEONE_COMPONENT_ID = "newSomeoneComponentId";

export type IUserRole = "creator" | "enduser" | "simplifiedEnduser";

interface ICreateOrUpdateUserAsAdminProps {
  mode: "create" | "update";
  userRole: IUserRole;
  userId?: number;
}

interface ICreateOrUpdateUserPayload extends InviteExternalFormData {
  itemsToBeGivenAccess: number[];
}

export const CreateOrUpdateUserAsAdmin = ({ mode, userRole }: ICreateOrUpdateUserAsAdminProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currentUser = useSelector(getCurrentUser);
  const projectGroupsWithNestedElements = useSelector(getAllProjectGroupsWithNestedElements);
  const userDetails = useSelector(getCreateOrEditUserDraft);
  const [permissions, setPermissions] = useState<
    (ICreatorDetailsPermission | IEnduserDetailsPermission)[]
  >(
    userRole === "creator"
      ? (userDetails.isUserAllowedByProjectOrProjectGroupArray as ICreatorDetailsPermission[])
      : (userDetails.isUserAllowedByProjectOrProjectGroupArray as IEnduserDetailsPermission[]),
  );

  const nameOfIdKey = userRole === "creator" ? "projectGroupId" : "projectId";

  const collaborators = useSelector(getCreators);
  const collaboratorsFeature = useSelector((state: RootState) =>
    getSubscriptionFeature(state, "number-of-available-creator-accounts"),
  );

  const currentNumberOfAvailableCollaborators = collaboratorsFeature
    ? collaboratorsFeature.QuantityCurrent
    : 0;

  const participants = useSelector(getEndusers);
  const participantsFeature = useSelector((state: RootState) =>
    getSubscriptionFeature(state, "number-of-available-enduser-accounts"),
  );

  const currentNumberOfAvailableParticipants = participantsFeature
    ? participantsFeature.QuantityCurrent
    : 0;

  useEffect(() => {
    dispatch(fetchSubscription({ componentId: NEW_SOMEONE_COMPONENT_ID }));
  }, []);

  const handleSelectAll = () => {
    setPermissions(
      permissions.map((permission: any) => {
        return {
          ...permission,
          allowed: permissions.filter((p: any) => p.allowed).length > 0 ? false : true,
        };
      }),
    );
  };

  const handleClick = (e: any) => {
    const { id } = e.target;
    // State update logic
    if (userRole === "simplifiedEnduser") {
      const thereIsExactlyZeroOrOnePermission =
        permissions.filter((p: any) => p.allowed).length <= 1;

      setPermissions(
        permissions.map((permission: any) => {
          return {
            ...permission,
            allowed:
              Number(permission[nameOfIdKey]) === Number(id) && thereIsExactlyZeroOrOnePermission
                ? !permission.allowed
                : false,
          };
        }),
      );
    } else {
      setPermissions(
        permissions.map((permission: any) => {
          return {
            ...permission,
            allowed:
              Number(permission[nameOfIdKey]) === Number(id)
                ? !permission.allowed
                : permission.allowed,
          };
        }),
      );
    }
  };

  const checkIfSubmitButtonShouldBeDisabled = () => {
    if (mode === "create") {
      switch (userRole) {
        case "simplifiedEnduser":
          return permissions.filter((p: any) => p.allowed).length === 0;
        case "creator":
          return currentNumberOfAvailableCollaborators === 0;
        case "enduser":
          return currentNumberOfAvailableParticipants === 0;
        default:
          return false;
      }
    }
    return false;
  };
  const nextButtonShouldBeDisabled = checkIfSubmitButtonShouldBeDisabled();

  const deduceNextDisabledTooltipText = () => {
    if (nextButtonShouldBeDisabled) {
      if (userRole === "enduser" || userRole === "creator") {
        return t("tooltip.noSlotAvailable");
      } else {
        return t("tooltip.pleaseSelectOneProjectToInviteSimplifiedEnduser");
      }
    }
    return "";
  };

  const rows = permissions.map((permission: any, i: number) => {
    const values =
      userRole === "creator"
        ? {
            id: String(permission["projectGroupId"]),
            allowed: permission.allowed,
            name: permission["projectGroupName"],
            projectGroupInWhichItIs: "",
          }
        : {
            id: String(permission["projectId"]),
            allowed: permission.allowed,
            name: permission["projectName"],
            projectGroupInWhichItIs: permission["projectGroupName"],
          };

    return {
      checkbox: (
        <Checkbox
          checked={permission.allowed}
          key={`${i}-${values.id}`}
          id={values.id}
          value={values.id}
          onClick={(e) => {
            e.stopPropagation();
            handleClick(e);
          }}
        />
      ),
      name: values.name,
      projectGroupInWhichItIs: values.projectGroupInWhichItIs,
    };
  });

  const {
    register,
    handleSubmit,
    // watch,
    setValue,
    formState: { errors },
  } = useForm<ICreateOrUpdateUserPayload>();
  let ref: any;
  const clearForm = () => {
    if (ref) {
      ref.reset();
    }
  };

  useEffect(() => {
    if (mode === "update") {
      setValue("newUserFirstName", userDetails.firstname);
      setValue("newUserLastName", userDetails.lastname);
      setValue("newUserEmail", userDetails.email);
      setPermissions(userDetails.isUserAllowedByProjectOrProjectGroupArray);
    }
    if (mode === "create") {
      // Construire l'array de "permission custom objects" à partir du store, où en tant qu'admin on a accès à tous les PG & projects
      if (userRole === "creator") {
        setPermissions(
          projectGroupsWithNestedElements.map((pgwne) => {
            return {
              allowed: false,
              projectGroupName: pgwne.name,
              projectGroupId: pgwne.id,
            };
          }),
        );
      }
      if (userRole === "enduser" || userRole === "simplifiedEnduser") {
        const filteredProjectGroups = projectGroupsWithNestedElements.filter((pgwne) => {
          return pgwne.auth_type !== "none";
        });

        const projectPermissionsCustomObjects = [] as IEnduserDetailsPermission[];

        filteredProjectGroups
          .filter((pgwne) => {
            if (userRole === "simplifiedEnduser") {
              return pgwne.auth_type === "simplified";
            } else {
              return pgwne.auth_type !== "simplified";
            }
          })
          .forEach((pgwne) => {
            const pgElements = [...pgwne.project_group_elements].sort((a, b) => a.order - b.order);
            pgElements.forEach((pge) => {
              projectPermissionsCustomObjects.push({
                projectId: Number(pge.project.id),
                allowed: false,
                projectName: pge.project.name,
                projectGroupName: pge.project_group.name,
                elementOrder: pge.order,
              });
            });
          });
        setPermissions(projectPermissionsCustomObjects);
      }
    }
  }, [userDetails.isUserAllowedByProjectOrProjectGroupArray]);

  const onSubmit = (data: ICreateOrUpdateUserPayload) => {
    if (userRole === "creator") {
      if (mode === "create") {
        dispatch(
          inviteCreatorWithPerms({
            firstname: data.newUserFirstName,
            lastname: data.newUserLastName,
            email: data.newUserEmail,
            pgIds: permissions.filter((p) => p.allowed).map((p: any) => Number(p.projectGroupId)),
            componentId: COLLABORATORS_TABLE_COMPONENT,
          }),
        ).then((res: any) => {
          navigate("/team");
          displayProperAlert({
            httpStatus: res.payload.status,
            successMsg: "alerts.collaboratorInvitedSuccessfully",
            errorMsg: "alerts.collaboratorInvitedFail",
            dispatch,
            t,
          });
        });
      }
      if (mode === "update") {
        dispatch(
          editCreatorPerms({
            userId: userDetails.id,
            allowedPgIds: permissions
              .filter((p) => p.allowed)
              .map((p: any) => Number(p.projectGroupId)),
            componentId: COLLABORATORS_TABLE_COMPONENT,
          }),
        ).then((res: any) => {
          navigate("/team");
          displayProperAlert({
            httpStatus: res.payload.status,
            successMsg: "alerts.collaboratorPermissionsEditedSuccessfully",
            errorMsg: "alerts.collaboratorPermissionsEditedFail",
            dispatch,
            t,
          });
        });
      }
    }

    if (userRole === "enduser") {
      if (mode === "create") {
        dispatch(
          inviteEnduserWithPerms({
            firstname: data.newUserFirstName,
            lastname: data.newUserLastName,
            email: data.newUserEmail,
            projectIds: permissions.filter((p) => p.allowed).map((p: any) => Number(p.projectId)),
            componentId: PARTICIPANTS_TABLE_COMPONENT,
          }),
        ).then((res: any) => {
          navigate("/learners");
          displayProperAlert({
            httpStatus: res.payload.status,
            successMsg: "alerts.participantInvitedSuccessfully",
            errorMsg: "alerts.participantInvitedFail",
            dispatch,
            t,
          });
        });
      }
      if (mode === "update") {
        dispatch(
          editEnduserPerms({
            userId: userDetails.id,
            allowedProjectIds: permissions
              .filter((p) => p.allowed)
              .map((p: any) => Number(p.projectId)),
            componentId: PARTICIPANTS_TABLE_COMPONENT,
          }),
        ).then((res: any) => {
          navigate("/learners");
          displayProperAlert({
            httpStatus: res.payload.status,
            successMsg: "alerts.participantPermissionsEditedSuccessfully",
            errorMsg: "alerts.participantPermissionsEditedFail",
            dispatch,
            t,
          });
        });
      }
    }

    if (userRole === "simplifiedEnduser" && mode === "create") {
      const checkedPerm = permissions.find((p) => p.allowed) as { projectId: number };
      dispatch(
        inviteExternalEnduserWithoutEmail({
          inviterId: currentUser ? currentUser.id : -1,
          projectId: checkedPerm.projectId,
          newUserFirstName: data.newUserFirstName,
          newUserLastName: data.newUserLastName,
          componentId: PARTICIPANTS_TABLE_COMPONENT,
        }),
      );
      navigate("/learners");
    }

    clearForm();
  };

  const Title = () => {
    let string = t(`pages.${userRole === "creator" ? "collaborators" : "participants"}.${mode}`);
    if (userRole === "simplifiedEnduser" && mode === "create") {
      string = t("pages.participants.createSimplified");
    }
    return (
      <div className="w-full flex items-center justify-evenly my-8">
        <div className="w-1/3">
          {userRole === "creator" ? (
            <AvailableFeatureCounter
              className="w-80 h-28"
              currentNumberOfAvailable={currentNumberOfAvailableCollaborators}
              totalNumberOfAvailable={
                collaboratorsFeature
                  ? collaboratorsFeature.QuantityCurrent + collaborators.length
                  : 0
              }
              text={t("pages.collaborators.collaborators")}
            />
          ) : null}
          {userRole === "enduser" ? (
            <AvailableFeatureCounter
              className="w-80 h-28"
              currentNumberOfAvailable={currentNumberOfAvailableParticipants}
              totalNumberOfAvailable={
                participantsFeature ? participantsFeature.QuantityCurrent + participants.length : 0
              }
              text={t("pages.participants.participants")}
            />
          ) : null}
        </div>
        <div className="flex flex-col items-center w-1/3">
          <h3 className="w-full text-center mt-8 mb-8 text-2xl font-bold">{string}</h3>
        </div>
        <div className="w-1/3"></div>
      </div>
    );
  };

  return (
    <div className="w-full h-full flex flex-col justify-between">
      <IsLoading componentId={NEW_SOMEONE_COMPONENT_ID} showSuccess={false} spinnerPlaceholder>
        <div>
          <Title />
          <form className="flex justify-center">
            <div className="h-full w-4/5 flex">
              <div className="flex">
                <CreateOrUpdateUserAsAdminForm
                  register={register}
                  errors={errors}
                  isFormSimplified={userRole === "simplifiedEnduser"}
                  readOnly={mode === "update"}
                  {...(mode === "update" ? { ...userDetails } : {})}
                />
              </div>
              <div className="flex flex-1">
                <CreateOrUpdateUserAsAdminPermissionsList
                  rows={rows}
                  userRole={userRole}
                  isCheckAllChecked={Boolean(
                    permissions.find((permission: any) => permission.allowed),
                  )}
                  handleSelectAll={handleSelectAll}
                  setPermissions={setPermissions}
                  permissions={permissions}
                />
              </div>
            </div>
          </form>
        </div>

        <FooterButtons
          cancelText={t("general.cancel")}
          cancelCb={() => navigate(-1)}
          nextText={mode === "create" ? t("general.create") : t("general.update")}
          nextCb={() => {
            handleSubmit(onSubmit)(); // asciant MVP https://github.com/react-hook-form/react-hook-form/issues/566#issuecomment-612328736
          }}
          nextDisabled={nextButtonShouldBeDisabled}
          nextDisabledTooltipText={deduceNextDisabledTooltipText()}
        />
      </IsLoading>
    </div>
  );
};
