import * as AllIcons from "@heroicons/react/outline";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IProject } from "../../../model/model";
import Badge from "../Badge/Badge";
import ThreeDotsDropdownMenu, { menuItem, menuItems } from "../Icon/ThreeDotsDropdownMenu";
import logoWixarPlaceholder from "src/common/util/logoWixarPlaceholder";
import { useSelector } from "react-redux";
import { mediaSelector } from "src/features/gallery/state/gallerySlice";
import _ from "lodash";

interface ButtonSquareIconProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  project: IProject;
  currentProject: number | undefined;
  handleThreeDotButtonClick: ({
    item,
    itemId,
    action,
  }: {
    item: string;
    itemId: number;
    action: string;
  }) => void;
  template?: boolean;
  isSelected?: boolean;
  showThreeDotsButton?: boolean;
  isCustomTemplateCard?: boolean;
  height?: string;
  width?: string;
  setEditedProjectId?: (id: number) => void;
  threeDotsMenuItemsPosition?: string;
}

const ButtonSquareIcons = ({
  template,
  project,
  currentProject,
  handleThreeDotButtonClick,
  isSelected = false,
  showThreeDotsButton = true,
  isCustomTemplateCard = false,
  height = "h-32",
  width = "w-full",
  setEditedProjectId,
  threeDotsMenuItemsPosition = "top-2 right-3",
  ...props
}: ButtonSquareIconProps) => {
  const { t } = useTranslation();

  const allMedias = useSelector(mediaSelector);

  const menuItems: menuItems = [
    {
      menuItemText: t("general.modify"),
      itemId: t("general.modify"),
      icon: "PencilAltIcon",
      disabled: project?.status === "Published",
    },
    {
      menuItemText: t("general.makeTemplate"),
      itemId: "Fait template",
      icon: "SaveAsIcon",
      // disabledTooltipText: t("tooltip.faitTemplate"),
    },
    {
      menuItemText: t("general.delete"),
      itemId: t("general.delete"),
      icon: "TrashIcon",
      disabled: project?.status === "Published",
    },
    // { menuItemText: "Favourite", icon: "StarIcon" },
  ];
  let starColor = "stroke-white";

  const [toggleStar, setToggleStar] = useState(false);

  if (toggleStar) {
    starColor = "fill-yellow-500 stroke-yellow-500";
  }

  if (project?.name.length > 28) {
    project.name.substring(0, 28) + "...";
  }

  const RelevantBadge = (projectStatus: string) => {
    switch (projectStatus) {
      case "Edit":
      default:
        return <div className="inline-flex items-center justify-center" />;
      case "Published":
        return (
          <Badge
            text={t("general.inPublication")}
            color="bg-green-500"
            textColor="text-green-100"
          />
        );
    }
  };

  const getBackgroundImage = () => {
    let url = logoWixarPlaceholder;
    let relevantSourceMedia = null;
    if (!_.isObject(project?.source_thumbnail)) {
      relevantSourceMedia = allMedias.find(
        (m) => Number(m.id) === Number(project?.source_thumbnail?.id),
      );
      if (relevantSourceMedia) {
        url = (
          relevantSourceMedia?.thumbnail_s3_url
            ? relevantSourceMedia?.thumbnail_s3_url
            : relevantSourceMedia?.s3_url
        ) as string;
      }
    } else if (project?.source_thumbnail?.id) {
      url = project?.source_thumbnail?.s3_url;
    }
    return url;
  };

  return (
    <div
      className="relative"
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
      }}
    >
      {/* We need to do these style shenanigans to have a sweet onHover animation on these buttons:
				- transform:scale() solutions change the button ultimate dimensions, ruining the layout
				- hover: background-size 1xx% solutions don't work when original value is background-size: cover
				- we need to use pseudo-elements, but can't pass dynamic urls in projectsInProjectGroup.scss without the trick below
		*/}
      <style>
        {`
				.project-card-${project.id}::before {
					background-image: url('${getBackgroundImage()}');
				}
	`}
      </style>
      <button
        className={`project-card ${width} ${height} project-card-${
          project.id
        } relative inline-flex rounded-lg font-medium bg-center bg-opacity-40 bg-blend-soft-light 
			${isSelected ? "ring-4 ring-purple-500 scale-105" : ""}`}
        {...props}
      >
        <div className="px-3 pt-2 pb-1 flex grow flex-col h-full justify-between w-full">
          <div
            className="flex justify-start invisible"
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
              event.stopPropagation();
            }}
          >
            <AllIcons.StarIcon
              onClick={() => setToggleStar(!toggleStar)}
              className={`w-6 h-6 ${starColor}`}
            />
          </div>
          <p className="text-left text-white leading-snug text-ellipsis overflow-clip card-title-outline">
            {project?.name}
          </p>
          {<div className="align-bottom">{RelevantBadge(project?.status)}</div>}
        </div>
      </button>{" "}
      {showThreeDotsButton && (
        <div
          className={`absolute ${threeDotsMenuItemsPosition} h-6 w-6 rounded-md overflow-visible`}
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            event.stopPropagation();
          }}
        >
          <ThreeDotsDropdownMenu
            projectId={project?.id}
            setEditedProjectId={setEditedProjectId}
            menuItems={
              isCustomTemplateCard
                ? menuItems.filter((mi: menuItem) => mi.menuItemText === t("general.delete"))
                : menuItems
            }
            handleClick={(e) => {
              handleThreeDotButtonClick({
                item: "project",
                itemId: project?.id,
                action: e.itemId,
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ButtonSquareIcons;
